import { Routes } from '@angular/router';

import { AccountComponent } from './account/account.component';

import { canActivateTeam } from '../shared/loginauth.gaurd';

export const routes: Routes = [
    {
        path: '', children: [
            { path: '',  redirectTo: '/account', pathMatch: 'full' },
            { path: 'account', component:AccountComponent  },
            { path: 'dash', canActivate: [canActivateTeam],  loadComponent: () =>
            import('./dash/dash.component').then((m) => m.DashComponent), children:[
                { path: '',  redirectTo: 'e-corner', pathMatch: 'full' },
                { path: 'hr', loadChildren: () => import('../../projects/hr/src/app/app.routes').then(mod => mod.routes) },
                { path: 'crm', loadChildren: () => import('../../projects/crm/src/app/app.routes').then(mod => mod.routes) },
                { path: 'books', loadChildren: () => import('../../projects/books/src/app/app.routes').then(mod => mod.routes)},
                { path: 'assets', loadChildren: () => import('../..//projects/assets/src/app/app.routes').then(mod => mod.routes)},
                { path: 'settings', loadChildren: () => import('../../projects/settings/src/app/app.routes').then(mod => mod.routes)},
                { path: 'profile', loadComponent: () => import('./profile/profile.component').then((m) => m.ProfileComponent)},
                { path: 'e-corner', loadChildren: () => import('../../projects/e-corner/src/app/app.routes').then(mod => mod.routes)},
                { path: 'accounts', loadChildren: () => import('../../projects/accounts/src/app/app.routes').then(mod => mod.routes)},
                { path: 'change-pwd', loadComponent: () => import('./change-pwd/change-pwd.component').then((m) => m.ChangePwdComponent)},
            ] },

        ],
    }
];
