import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from '../shared/auth-interceptor.service';
import { AppInterceptor } from '../shared/authInterceptor';
import { TranslateModule } from '@ngx-translate/core';
import { provideTranslation } from '../shared/translate';
import { provideServiceWorker } from '@angular/service-worker';
import { provideQuillConfig } from 'ngx-quill/config';
export const appConfig: ApplicationConfig = {
  providers: [provideHttpClient(withFetch(), withInterceptors([AuthInterceptor])), provideRouter(routes), provideClientHydration(), provideAnimations(),
    importProvidersFrom([
        HttpClientModule,
        TranslateModule.forRoot(provideTranslation())
    ]),
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }, provideAnimations(), provideServiceWorker('ngsw-worker.js', {
        enabled: true,
        registrationStrategy: 'registerImmediately'
    }), provideQuillConfig({
        modules: {
            syntax: true,
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }], // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
                // [{ 'direction': 'rtl' }],                         // text direction
                [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['clean'], // remove formatting button
                ['link'] // link and image, video
            ]
        }
    })]
};
