import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let existSession,authToken;
  try {
    let ses:any=localStorage.getItem('smarte2e_smato');
    existSession = JSON.parse(ses);
    authToken = existSession.akey;
  } catch (error) {
    existSession ={};
    authToken = '';
  }
  request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + authToken) });// This clones HttpRequest and Authorization header with Bearer token added
  request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
  request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    return next.handle(request);
  }
}