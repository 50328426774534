import { Injectable, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonService } from './common.service';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute, UrlTree, CanActivateFn } from '@angular/router';
import { lastValueFrom, Observable, of } from 'rxjs';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
export const canActivateTeam: CanActivateFn =
  async () => {
    let cs = inject(CommonService);
    let platformId = inject(PLATFORM_ID);
    let router = inject(Router);
    const state1 = router.routerState;
    let state = state1.snapshot;
    let existSession: any = {};
    if (isPlatformBrowser(platformId)) {
      existSession = localStorage.getItem('smarte2e_smato');
    }
    if (existSession == null || existSession == '{}' || existSession == undefined) {
      return false;
    }
    else {
      return true;
    }
  }

